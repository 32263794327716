@import '../../../style/breakpoint.scss' ;

.footer{
    background-color: rgba(67, 75, 73, 1);
    height: 405px;
    padding: 50px 170px 40px;
    box-sizing: border-box;


    @include md{
        padding:50px 30px 40px ;
    }

    @include lg{
        padding: 50px 50px 40px;
    }



    .copyright{
        color: #fafafa;
        text-align: center;
        font-family: 'Karla-Regular';
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 16px;

        @include sm{
            margin: 16px 0 0;
        }
    }

    .main{
        display: flex;
        flex-direction: column;
        border-bottom: 0.1px solid #fafafa73;

        .footer-logo{
            margin-bottom: 20px;

            .footer-img{
                width: 250px;
                height: 50px;
                display: block;
                transform: translateX(-20px);

            }
        }


        .content{
            display: flex;
            justify-content: space-between;
            color: white;
            padding-bottom: 30px;

            .footer-card{
               

                .footer-title{
                    font-size: 17px;
                    letter-spacing: 0.5px;
                    margin: 0;
                }

                .footer-subtitle{
                    width: 50%;
                    font-size: 16px;
                    line-height: 27px;
                    color: #fafafab8;
                    font-family: 'Karla-Regular';
                    letter-spacing: 0.5px;

                    @include md{
                        width: 90%;
                    }
                }

                .row{
                    display: flex;
                    
                    .column:nth-child(1){
                        width: 35%;
                        color: #fafafaab ;
                        letter-spacing: 0.5px;
                        font-size: 16px;
                        font-family: 'Karla-Regular';


                        @include md{
                            width: 25%;
                        }
                    }

                    .column:nth-child(2){
                        width: 60%;
                        box-sizing: border-box;
                        padding-left: 10px;
                        color: #fafafaab ;
                        letter-spacing: 0.5px;
                        font-size: 16px;
                        font-family: 'Karla-Regular';
                    }


                    .column{
                        span{
                            img{
                                margin-right: 8px;
                            }

                        }
                    }

                    
                }

                .list{
                    padding: 0;

                    .list-item{
                        list-style: none;
                        display: block;
                        margin-bottom: 4px;

                        .link{
                            color: #fafafab8;
                            text-decoration: none;
                            font-family: 'Karla-Regular';
                            font-weight: 400;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }

           
        }
    }
}


@media (max-width : 992px){
    .footer{
        background-color: #434b49;
        padding: 50px 30px 40px;
        box-sizing: border-box;
        height: auto;

        .copyright{
            margin: 16px 0 0;
        }

        .main .content .footer-card{
            

                    .list .list-item{
                        margin-bottom: 8px;
                    }

                    .footer-subtitle{
                        width: 90%;
                    }

                    .row{
                        .column:nth-child(1) , .column:nth-child(2){
                            padding: 0;
                        }
                    }
                
            
        }
    }
}





