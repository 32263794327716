@import '../../style/breakpoint.scss' ;

.contact{

    .jumbotron{
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;


        .title{
            font-size: 48px;
            color: #fff;
            transform: translateY(10px);
            font-family: 'Karla-Bold';

        }   
    }


    .information{
        padding: 0 120px;
        height: 500px;
        background-color: #000;
        position: relative;

        @include md{
            height: auto;
            padding: 0 40px;

        }

        @include lg{
            height: auto;
            padding: 0 40px;

        }

        .wrapper{
            transform: translateY(-70px);

            .map {
                position: relative;

                .img{
                    width: 100%;
                    height: 440px;
                    position: relative;
                }

                .direction{
                    position: absolute;
                    right: 0;
                    transform: translate(-25px , 10px);
                }
            }

            .wrap-info{
                background-color: #fff;
                padding: 30px 40px;

                @include md{
                    padding: 30px 25px;
                }
    
                .row-1 , .row-2 , .row-3{
                    margin-bottom: 30px;
                }
                
                .row-1 , .row-2{
                    display: flex;
                    justify-content: space-between;
    
    
                    .column-1 , .column-2{
                        width: 45%;

                        input{
                            height: 40px;
                            width: 100%;
                            box-sizing: border-box;
                            padding: 0 0 0 20px;
                            font-size: 17px;
                            border: 1px solid #DBDBDB;
                            color: #8B8B8B;
                            letter-spacing: 1px;

                            @include md{
                                font-size: 12px;
                            }
        
                            &:focus{
                                outline: none;
                            }
                        }

                        .error{
                            margin: 5px 0 0;
                            display: none;
                        }

                        .error.active{
                            color: red;
                            display: inline-block;
                            letter-spacing: 1px;
                            font-size: 14px;
                        }
                    }
                    
                }
    
                .row-3 textarea{
                    width: 100%;
                    height: 160px;
                    box-sizing: border-box;
                    padding: 20px 0 0 20px;
                    font-size: 17px;
                    border: 1px solid #DBDBDB;
                    color: #8B8B8B;
                    letter-spacing: 1px;
    
                    &:focus{
                        outline: none;
                    }
                }
    
    
                .row-4 .action{
                    float: right;
                    width: 160px;
                    height: 45px;
                    border-radius: 30px;
                    background: #E75746;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    letter-spacing: 1.5px;
    
                }
            }
        }

    }
}


@media (max-width : 468px){
    .contact{

        .jumbotron{
            height: 300px;
            background-size: cover;
            background-position: center;

            .title{
                font-size: 32px;
            }
        }

        .information{
            padding: 0 20px ;
            height: 950px;

            .wrapper{
                transform: none;

                .map .img{
                    height: 250px;

                }

                .wrap-info{
                    padding: 30px 20px;
                    font-size: 14px;

                    .row-3 textarea{
                        font-size: 14px;
                    }

                    .row-2 , .row-3 , .row-4{
                        transform: translateY(-30px);
                    }

                    .row-1 , .row-2{
                        flex-direction: column;

                        .column-1 , .column-2{
                            width: 100%;
                            margin-bottom: 18px;

                            input{
                                font-size: 14px;
                                padding:  0 0 0  10px;
                            }
                        }
                    }
                }
            }
        }
    }
}