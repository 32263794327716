@import '../../../style/breakpoint.scss' ;
@import '../../../style/colors.scss' ;

.header.active{
    background-color: #F0F0F0;
}


.header{
    font-family: 'Karla-Regular';
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 70px 0 70px;
    box-sizing: border-box;
    background-color: transparent;
    z-index: 3;
    position: fixed;

    @include lg{
        padding: 0 30px 0 35px;
        justify-content: unset;
    }

    .header-logo{

    

        .header-img{
            width: 180px;
            height: 40px;
            display: block;

            @include lg{
                width: 175px;
            }


        }

        .hamburger-menu{
            display: none;
        }

    }


    .header-link{
        box-sizing: border-box;
        width: 950px;
        margin-left: 90px;

        @include lg{
            width: unset;
            margin-left: 60px;
        }

        .list{
            display: flex;
            flex-direction: row;
            padding: 0;

            .list-item{
                list-style: none;
                position: relative;
                margin-right: 40px;

                @include lg{
                    margin-right: 25px;
                }
                

                .link{
                    color: rgba(116, 149, 168, 1);
                    font-size: 17px;
                    letter-spacing: 0.5px;
                    padding: 0;
                    margin: 0;
                    text-decoration: none;
                    letter-spacing: 0.5px;
                    font-family: 'Karla-Bold' , sans-serif;

                    @media (max-width : 1024px){
                        font-size: 14px;
                    }
                }

                .active-link{
                    color: #E75746;
                    position: relative;
                    
                    &::after{
                        content: '';
                        width: 50%;
                        height: 2px;
                        position: absolute;
                        bottom: -10px;
                        background-color: #E75746;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

}


@media (min-width : 0px) and (max-width : 992px){
    .header{
        width: 100%;
        display: block; 
        padding: 0; 
        position: fixed;
        background-color: #F0F0F0;
        height: 65px;

        .header-logo{
            width: 100%;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;


            .header-img{
                max-width: 180px;
                display: block;
                max-height: 40px;
                
            }

            .hamburger-menu{
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .hamburger-btn{
                    position: relative;
                    height: 3px;
                    width: 20px;
                    background-color: $primary;
                    transition: all 0.5s ease-in-out;

                    &::before , &::after{
                        position: absolute;
                        content: '';
                        width: 20px;
                        height: 3px;
                        background-color: $primary;
                        transition: all 0.5s ease-in-out;
                    }


                    &::before{
                        transform: translateY(-6px);
                    }

                    &::after{
                        transform: translateY(6px);
                    }
                }
            }

            .hamburger-menu.open .hamburger-btn{
                transform: translateX(-30px);
                background: transparent;
    
                &::before{
                    transform: rotate(45deg) translate(22px , -22px);
                }
    
    
                &::after{
                    transform: rotate(-45deg) translate(22px , 22px);
                }
            }
    
        }

        

        .header-link{
            width: 100%;
            overflow-x: hidden;

            .list{
                flex-direction: column;
                margin: 0;
                position: absolute;
                left: -200px;
                padding: 20px 0;
                transition: all 0.5s;

               


                .list-item{

                    .link{
                        display: block;
                        padding: 10px 0 10px 20px;

                        :visited{
                            background: none;
                        }
                    }

                    .link.active-link{
                        &::after{
                            display: none;
                        }
                    }
                }
            }

            .list.active{
                left: 0;
                transition: all 1s;
                background-color: #F0F0F0;
                width: 100%;

            }

        }


        .header-link.active{
           background-color: #F0F0F0;
        }
    }
}