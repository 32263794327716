
@import '../../style/breakpoint.scss' ;
@import '../../style/colors.scss' ;

.about{

    .jumbotron{
        height: 330px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;

        @include sm{
            height: 250px;
        }

        @include md{
            height: 270px;
        }

        @include lm{
            height: 270px;
        }

        @include lg{
            height: 280px;
        }

        .title{
            text-align: center;
            margin: 0;
            padding: 0;
            font-size: 48px;
            font-family: 'Karla-Bold';
            letter-spacing: 1.5px;
            line-height: 50px;
            color: white;
            transform: translatey(25px);

            @include sm{
                font-size: 28px;
            }

            @include md{
                font-size: 36px;
            }

            @include lm{
                font-size: 36px;
            }

            @include lg{
                font-size: 32px;
            }
        }
    }

    .journey{
        height: 660px;
        padding: 70px 170px 60px;
        box-sizing: border-box;

        @include sm{
            padding: 20px 30px 60px;
            box-sizing: border-box;

            &::-webkit-scrollbar{
                display: none;
            }
        }


        @include md{
            height: 700px;
            padding: 70px 42px 60px;
        }

        @include lm{
            height: 700px;
            padding: 70px 42px 60px;
        }

        @include lg{
            padding: 70px 50px 60px;
        }

        .title{
            text-align: center;
            font-family: 'Karla-Bold';
            font-size: 40px;
            color: rgba(231, 87, 70, 1);
            letter-spacing: 1.5px;

            @include sm{
                text-align: center;
                font-family: 'Karla-Bold';
                font-size: 28px;
                color: rgba(231, 87, 70, 1);
                letter-spacing: 1.5px;
                position: relative;
            }

            @include lm{
                font-size: 32px;
                margin-top: 0;
            }

            @include lg{
                font-size: 32px;
                margin-top: 0;
            }
        }

        .body{
            margin: 45px 0;
            font-family: 'Karla-Bold';
            font-size: 22px;
            letter-spacing: 0.5px;
            text-align: center;
            line-height: 32px;
            color: rgba(67, 75, 73, 1);

            @include sm{
                font-size: 17px;
                line-height: 28px;
                margin: 35px 0;
            }

            @include md{
                font-size: 20px;
            }

            @include lm{
                font-size: 20px;
            }

            @include lg{
                font-size: 18px;
                margin: 60px 0;
            }
        }

        .roadmap{

            @include sm{
                overflow-x: scroll;
            }

            @include md{
                overflow-x: scroll;

                &::-webkit-scrollbar{
                    display: none;
                }
            }

            @include lm{
                overflow-x: scroll;

                &::-webkit-scrollbar{
                    display: none;
                }
            }

            @include lg{
                overflow-x: scroll;
                height: 300px;
            }
        }
        
    }
    


    .team{
        box-sizing: border-box;
        padding: 50px 200px 150px;

        @include sm{
            padding: 50px 30px 70px;
        }

        @include md{
            padding: 50px 20px 70px;
        }

        @include lm{
            padding: 50px 20px 70px;
        }

        @include lg{
            padding: 50px 40px 150px;
        }

        .team-title{
            text-align: center;
            margin-bottom: 85px;
            font-size: 40px;
            font-family: 'Karla-Bold';
            color: rgba(231, 87, 70, 1);

            @include sm{
                font-size: 32px;
                position: relative;

                &:after{
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 50px;
                    background-color:rgba(231, 87, 70, 1) ;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            
        }

        .team-wrapper{

            @include sm{
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .team-card{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;

                @include sm{
                    padding: 0 0 30px;
                }

                .avatar{
                    display: inline-block;

                    @include sm{
                        width: 100%;
                    }

                    img{
                        border-radius: 50%;
                        width: 200px;

                        @include sm{
                            width: 100%;
                        }
                    }
                }

                .title{
                    font-size: 18px;
                    font-family: 'Karla-Regular';
                    color: rgba(86, 111, 126, 1);

                    @include sm{
                        font-size: 14px;
                        text-align: center;
                    }
                }

                .name{
                    font-size: 20px;
                    font-family: 'Karla-Bold';
                    color: rgba(231, 87, 70, 1);
                    margin: 10px 0 10px;
                    position: relative;
                    text-align: center;

                    @include sm{
                        font-size: 18px;
                    }

                    &:after{
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 50px;
                        background-color:rgba(231, 87, 70, 1) ;
                        bottom: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .avatar{
                    @include sm{
                        width: 100%;
                    }

                    .img{
                        width: 100%;
                    }
                }  
            }
        }

        .see{
            width: 240px;
            height: 60px;
            color: #E75746;
            background: transparent;
            border-radius: 30px;
            font-size: 17px;
            font-family: 'Karla-Bold';
            display: block;
            border: 2px solid #E75746;
            margin: 0 auto;
            cursor: pointer;
            display: none;
        }
    }


    .dattabot{
        padding: 0 80px 0 0;
        height: 615px;

        @include sm{
            padding: 0 20px 0 0;
            height: auto;
        }

        @include md{
            padding: 0 20px 0 0;
            height: auto;
        }

        @include lm{
            padding: 0 20px 0 0;
            height: auto;
        }

        @include lg{
            padding: 0 40px 0 0;
        }

        .wrapper{
            background-color: rgba(211, 225, 233, 1);
            border-radius: 0 300px 0 0;

            @include sm{
                background-color: #d3e1e9;
                border-radius: 0 150px 0 0;
            }

            @include lm{
                background-color: #d3e1e9;
                border-radius: 0 150px 0 0;
            }

            .grid{
                flex-direction: row;

                @include sm{
                    flex-direction: column-reverse;
                }

                .logo{
                    position: relative;

                    @include sm{
                        display: flex;
                        justify-content: center;
                    }

                    @include md{
                        width: 100%;
                        padding-top: 45px;
                    }

                    @include lm{
                        width: 100%;
                        padding-top: 45px;
                    }

                    @include lg{
                        padding:  0;
                    }
    
                    img{
                        position: absolute;
                        top : -50px ;

                        @include sm{
                            position: initial;
                            width: 325px;
                            height: 380px;
                            bottom: 0;
                            top: 45px; 
                        }


                        @include md{
                            width: 100%;
                            bottom: 0px;
                            position: unset;
                        }

                        @include lm{
                            width: 100%;
                            bottom: 0px;
                            position: unset;
                        }

                        @include lg{
                            position: relative;
                            width: 90%;
                            top: 0;
                        }
                    }
                }
    
                .information{
                    padding: 80px 120px 50px 0;

                    @include sm{
                        padding: 20px;
                    }

                    @include md{
                        padding: 80px 0 50px 0;
                    }

                    @include lm{
                        padding: 80px 0 50px 0;
                    }

                    @include lg{
                        padding: 80px 60px 50px 0;
                    }
    
                    .name{
                        font-family: 'Karla-Bold';
                        letter-spacing: 1.5px;
                        font-size: 40px;
                        color: rgba(231, 87, 70, 1);
                        margin: 0;

                        @include sm{
                            font-size: 28px;
                        }

                        @include md{
                            font-size: 36px;
                        }

                        @include lm{
                            font-size: 36px;
                        }
                    }
    
                    .title{
                        font-family: 'Karla-Bold';
                        font-size: 30px;
                        letter-spacing: 1.5px;
                        color: rgba(231, 87, 70, 1);
                        margin: 10px 0 0;

                        @include sm{
                            font-size: 20px;
                            line-height: 24px;
                        }

                        @include md{
                            font-size: 24px;
                        }

                        @include lm{
                            font-size: 24px;
                        }
                    }
    
    
                    .body{
                        font-size: 20px;
                        font-family: 'Karla-Regular';
                        letter-spacing: 0.5px;
                        color: rgba(86, 111, 126, 1);
                        line-height: 30px;

                        @include sm{
                            font-size: 16px;
                        }


                        @include md{
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .back{
            position: absolute;
            bottom: -18px;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
            font-size: 14px;
            font-family: 'Karla-Bold';
            background-color: #e5e5e5;
            padding: 10px 25px;
            color: $primary;
            letter-spacing: 0.5px;
            border-radius: 30px;
            cursor: pointer;
        }
    }
}
