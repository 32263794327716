

@import '../../../style/breakpoint.scss' ;
@import '../../../style/colors.scss' ;

.tech {

    padding-top: 60px;

    .item{
        padding: 50px 170px 70px 100px;
        background-color: #000;
        position: relative;

        @include md{
            padding: 50px 25px 70px;
        }

        @include lm{
            padding: 50px 25px 100px;
        }

        @include lg{
            padding: 50px 50px 70px 70px;
        }

        .wrapper{

            .col-1{

                @include md{
                    max-height: 350px;
                }

                @include lm{
                    max-height: 350px;
                }

                .logo{

                    @include md{
                        height: 100%;
                    }

                    @include lm{
                        height: 100%;
                    }

                    img{
                        border-radius: 0 150px 0 0;
                        width: 100%;

                        @include md{
                            width: 95%;
                            height: 100%;
                        }

                        @include lm{
                            width: 95%;
                            height: 100%;
                        }
        
                        @include lg{
                            width: 80%;
                        }
                    }
                }
            }

            .col-2{

                .information{
                    padding: 0 50px;
                    height: 100%;
                    position: relative;

                    @include sm{
                        padding: 0 15px 0 25px;
                    }

                    @include md{
                        padding: 0 15px 0 25px;
                    }

                    @include lm{
                        padding: 0 15px 0 25px;
                    }
    
                    @include lg{
                        padding: 0;
                    }
        
                    .category{
                        margin: 0 0 10px;
                        font-size: 24px;
                        font-family: 'Karla-Bold';
                        letter-spacing: 1px;
                        color: #E75746;

                        @include md{
                            font-size: 21px;
                        }

                        @include lm{
                            font-size: 21px !important;
                        }
    
                        @include lg{
                            font-size: 21px;
                        }
                    }
        
                    .title{
                        font-size: 40px;
                        line-height: 50px;
                        color: #fafafa;
                        letter-spacing: 1px;
                        font-family: 'Karla-Bold';
                        margin: 0;
                        height: 150px;

                        @include sm{
                            height: 115px;
                        }


                        @include md{
                            font-size: 24px;
                            line-height: 32px;
                            height: 100px;
                        }

                        @include lm{
                            font-size: 24px;
                            line-height: 32px;
                            height: 110px;
                        }
    
                        @include lg{
                            font-size: 28px;
                            line-height: 38px
                        }
        
                    }
        
                    .action{
                        margin: 15px 0 30px;

                        @include lm{
                            margin: 0;
                        }
        
                        button{
                            width: 120px;
                            height: 37px;
                            color: #7495A8;
                            font-size: 14px;
                            letter-spacing: 0.5px;
                            margin-right: 10px;
                            border: 1px solid #7495A8;
                            border-radius: 42px;
                            background-color: transparent;
                            cursor: pointer;


                            @include md{
                                width: 95px;
                                font-size: 12px;
                                height: 34px;
                            }

                            @include lm{
                                width: 95px;
                                font-size: 12px;
                                height: 34px;
                            }
                        }
        
                        .active{
                            background-color: #E75746;
                            color: #fff;
                            border: none;
                        }
                    }
        
        
                    .content .body{
                        font-size: 18px;
                        font-family: 'Karla-Regular' , sans-serif;
                        line-height: 27px;
                        letter-spacing: 0.5px;
                        color: #ffffffb3;

                        @include md{
                            font-size: 16px;
                        }

                        @include lm{
                            font-size: 16px;
                        }
    
                        @include lg{
                            font-size: 16px;
                        }
                    }
    
                    .content .image{
                        display: none;
    
                    }
        
        
                    .pagination {
                        position: absolute;
                        bottom: 0;

                        @include lm{
                            bottom: -38px;
                        }
                        
                        .action{
    
                            button{
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                font-size: 25px;
                                color: #fff;
                                font-family: 'Karla-Bold';
                                box-sizing: border-box;

                                @include lm{
                                    width: 36px;
                                    height: 36px;
                                }
                            }
        
                        }
    
                        .action .active{
                            background-color: #E75746;
    
                        }
    
                        .action .notActive{
                            background-color: #D3E1E9;
                        }
                        
                    }
                }
            }
        }

        .back{
            position: absolute;
            bottom: -18px;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
            font-size: 14px;
            font-family: 'Karla-Bold';
            background-color: #e5e5e5;
            padding: 10px 25px;
            color: $primary;
            letter-spacing: 0.5px;
            border-radius: 30px;
            cursor: pointer;
        }
    }
}


@media (max-width : 468px){

    .tech{

        .jumbotron{
            height: 300px;
            background-size: cover;
            background-position: center;

            .title{
                font-size: 38px;
            }
        }

        .item{
            padding: 30px 40px 70px 20px;
    
            .wrapper{
                flex-direction: column-reverse;  
                
                .logo{
                    display: none;
                }
    
                .information{
                    padding: 0 !important;
    
                    .category{
                        margin: 0px 0 15px !important;
                        font-size: 20px !important;
                    }
    
                    .title{
                        font-size: 24px !important;
                        line-height: 30px !important;
                    }

                    .action{

                        button{
                            width: 90px !important;
                            height: 30px !important;
                            font-size: 12px !important;
                        }
                    }

                    .content .image{
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        padding: 20px 30px;
                        display: block;
                        position: relative;
    
                        img{
                            width: 90%;
                            height: 300px;
                            border-radius: 0 100px 0 0;
                        }

                        .prev{
                            width: 38px;
                            height: 35px;
                            border-radius: 50%;
                            border: none;
                            font-size: 20px;
                            color: #fff;
                            font-family: 'Karla-Bold';
                            box-sizing: border-box;
                            position: relative;
                            left: -20px;
                        }

                        .next{
                            width: 38px;
                            height: 35px;
                            border-radius: 50%;
                            border: none;
                            font-size: 20px;
                            color: #fff;
                            font-family: 'Karla-Bold';
                            box-sizing: border-box;
                            position: relative;
                            right: -20px;

                        }

                        .active{
                            background-color: #E75746;
    
                        }
    
                        .notActive{
                            background-color: #D3E1E9;
                        }

                    }

                    .content .body{
                        font-size: 15px !important;
                    }

                    .pagination{
                        display: none;
                        
                    }
                }
    
            }
        }
    }
    
}