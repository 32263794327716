
@import '../../style/colors.scss' ;
@import '../../style/breakpoint.scss' ;

.home{
    scroll-behavior: smooth;

    .landing{
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;

        @include md{
            height: 75vh;
            overflow: hidden;
        }

        @include lg{
            height: 80vh;
            overflow: hidden;
        }

        @include lm{
            height: 75vh;
            padding-top: 60px;
            background-image: unset;
            overflow: hidden;
        }

        .video-wrap{

            position: absolute;
            width: 100%;
            height: 100%;

            @include sm{
                left: -50%;
            }

            video{
                min-width: 100%;
                z-index: 2;
                min-height: 100vh;
    
                @include sm{
                    min-width: 100%;
                    z-index: 0;
                    min-height: 100vh;
                    left: -50%;
                    transform: translateX(-15%);
                }

                @include lm{
                    width: unset;
                    height: unset;
                    min-height: 75vh;
                    transform: translateY(30px);
                }

                @include lg{
                    min-height: 80vh;
                }
            }
        }


       
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2;
            

            .content-title{
                font-size: 48px;
                color: white;
                font-family: 'Karla-Regular';
                letter-spacing: 1.5px;
                text-align: center;
                font-weight: 700;
                margin: 0;

                @include md{
                    font-size: 36px;
                }

                @include lg{
                    font-size: 38px;
                }
            }


            .content-body{
                font-size: 20px;
                font-family: 'Karla-Regular' ;
                font-weight: 400;
                text-align: center;
                color: #fafafabf;
                width: 55%;
                letter-spacing: 0.5px;
                line-height: 30px;
                margin: 30px 0 60px;
                

                @include md{
                    width: 80%;
                }
                
                @include lm{
                    width: 80%;
                }
            }



            .content-action{
                width: 288px;
                height: 72px;
                background-color: $primary;
                color: #fafafa;
                font-family: 'Karla-Bold';
                font-size: 20px;
                letter-spacing: 0.5px;
                border-radius: 40px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            }

        }
    }


    .technology{
        box-sizing: border-box;
        text-align: center;

        .content{

            .content-title{
                padding: 70px 0 30px;

                @include lg{
                    padding: 45px 0 30px;
                }

                span:nth-child(1){
                    letter-spacing: 1.5px;
                    font-size: 48px;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: $primary;
                    color: white;

                    @include md{
                        font-size: 42px;
                    }
                }
        
                span:nth-child(2){
                    margin-left: 20px;
                    font-size: 48px;
                    font-family: 'Karla-Bold';
                    color: $primary;
                    font-weight: 700;
                    letter-spacing: 1.5px;

                    @include md{
                        font-size: 42px;
                    }
                }
            }

            .content-body{
                color: #566f7e;
                font-family: 'Karla-Regular';
                letter-spacing: 0.5px;
                padding: 0 120px;
                line-height: 28px;
                font-size: 20px;
                margin: 15px 0 60px;

                @include md{
                    padding: 0 35px;
                }

                @include lg{
                    padding: 0 65px;
                }
                
            }


            .group{
                padding: 0 100px;
                
                @include md{
                    padding: 0 45px;
                }

                @include lm{
                    padding: 0 45px;
                }

                @include lg{
                    padding: 0 50px;
                }

                .wrapper{
                    border-radius: 20px;
                    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.26) ;

                    .column-1{
                        display: flex;
                        flex-direction: column;

                        @include md{
                            flex-direction: unset;
                        }

                        @include lm{
                            flex-direction: unset;
                        }

                        .content-1{

                            img{
                                width: 100%;
                                height: 300px;
                                border-radius: 20px 0 0 0;
                            }
                            
                        }

                        .content-4{
                            padding: 40px 60px 50px 70px;
                            box-sizing: border-box;
                            position: relative;
                            overflow-x: hidden;

                            @include md{
                                padding: 20px 30px 25px 45px;
                            }

                            @include lg{
                                padding: 20px 35px 30px 50px;
                            }

                            @include lm{
                                padding: 15px 20px 20px 45px;
                            }
    
                            .body{
                                color : rgba(116, 149, 168, 1);
                                font-family: 'Karla' , sans-serif;
                                letter-spacing: 0.5px;
                                line-height: 24px;
                                font-size: 16px;
                                margin: 0;
                                text-align: left;
                            }
    
                            &::after{
                                content: '1';
                                font-size: 110px;
                                top: -24px;
                                left: -3px;
                                color: rgba(255, 235, 236, 1);
                                padding: 0;
                                position: absolute;
                                font-family: 'Karla-Bold';
    
                            }
                        }
                    }


                    .column-2{

                        display: flex;
                        flex-direction: column;

                        @include md{
                            flex-direction: unset;
                        }

                        @include lm{
                            flex-direction: unset;
                        }

                        .content-2{
                            box-sizing: border-box;
                            padding: 40px 50px 50px 60px;
                            position: relative;
                            min-height: 300px;
                            overflow-y: hidden;

                            @include md{
                                padding: 15px 20px 0px 60px;
                                position: relative;
                                min-height: 210px;
                            }

                            @include lg{
                                padding: 20px 30px 35px 60px;
                            }

                            @include lm{
                                padding: 15px 20px 30px 65px;
                                min-height: unset;
                            }

    
                            .body{
                                color: #566f7e;
                                font-family: 'Karla' , sans-serif;
                                letter-spacing: 0.5px;
                                line-height: 24px;
                                font-size: 17px;
                                margin: 0;
                                text-align: left;
                            }
    
                            &::after{
                                content: '2';
                                font-size: 110px;
                                top: -24px;
                                left: -3px;
                                color: rgba(255, 235, 236, 1);
                                padding: 0;
                                position: absolute;
                                font-family: 'Karla-Bold';
    
                            }
                        }

                        .content-5{
                            img{
                                width: 100%;
                                height: 200px;
                            }
                        }
                    }


                    .column-3{
                        display: flex;
                        flex-direction: column;

                        @include md{
                            flex-direction: unset;
                        }

                        @include lm{
                            flex-direction: unset;
                        }

                        .content-3{
                            box-sizing: border-box;
    
                            img{
                                border-radius: 0 20px 0 0;
                                height: 300px;
                                width: 100%;

                                @include md{
                                    border-radius: 0;
                                    height: 200px;
                                }

                                @include lm{
                                    border-radius: 0 0px 0 20px;
                                }
                            }
                        }

                        .content-6{
                            padding: 40px 20px 50px 60px;
                            position: relative;
                            box-sizing: border-box;
                            overflow-x: hidden;

                            @include md{
                                padding: 30px 20px 50px 60px;
                            }
                            
                            @include lg{
                                padding: 20px 20px 0px 60px;
                            }
    
                            .body{
                                color: #566f7e;
                                font-family: 'Karla' , sans-serif;
                                letter-spacing: 0.5px;
                                line-height: 24px;
                                font-size: 16px;
                                margin: 0;
                                text-align: left;

                                @include lm{
                                    font-size: 17px;
                                }
                            }
    
                            &::after{
                                content: '3';
                                font-size: 110px;
                                top: -24px;
                                left: -3px;
                                color: rgba(255, 235, 236, 1);
                                padding: 0;
                                position: absolute;
                                font-family: 'Karla-Bold';
    
                            }
                        }
                    }
                }
            }
        }

        

        p{
            color: rgba(86, 111, 126, 1);
            font-family: 'Karla-Bold';
            letter-spacing: 0.5px;
            line-height: 28px;
            font-size: 20px;
        }
    }

    .solution{
        min-height: 660px;
        box-sizing: border-box;
        padding: 150px 150px 120px 100px; 

        @include md{
            padding: 150px 35px 120px 40px;
        }

        @include lm{
            padding: 150px 45px 120px 45px;
        }

        @include lg{
            padding: 90px 60px 80px;
        }

        .content{
            display: flex;

            .information{
                width: 300px;

                @include md{
                    width: 100%;
                }

                @include lm{
                    width: unset;
                }

                @include lg{
                    width: 300px;
                    padding-right: 25px;
                    box-sizing: border-box;
                }
                

                .title{

                    span:nth-child(1){
                        letter-spacing: 1.5px;
                        font-size: 48px;
                        -webkit-text-stroke-width: 1px;
                        -webkit-text-stroke-color: $primary;
                        color: white;
                        display: block;

                        
                    }
            
                    span:nth-child(2){
                        display: block;
                        font-size: 48px;
                        font-family: 'Karla-Bold';
                        color: $primary;
                        font-weight: 700;
                        letter-spacing: 1.5px;

                       
                    }
                }

                .body{
                    font-family: 'Karla' , sans-serif;
                    font-size: 20px;
                    text-align: left;
                    color: rgba(86, 111, 126, 1);
                    letter-spacing: 0.5px;
                    line-height: 30px;

                    @include lg{
                        font-size: 19px;
                    }
                }
            }


            .product{
                overflow-x: scroll;
                padding: 40px 0 0 0;
                min-height: 400px;
                scrollbar-width: none;

                 

                .list{
                    display: flex;
                    flex-direction: row;

                    .item{
                        max-height: 300px;
                        min-width: 265px;
                        margin-right: 15px;


                        @include md{
                            max-height: 280px;
                        }

                        @include lg{
                            max-height: 260px;
                            margin-right: 20px;
                        }

                        .item-img{
                            height: 100%;
                        

                            img{
                                height: 100%;
                                width: 100%; 
                                border-radius: 15px 15px 0 0;
                            }
                        }
                    }

                    .info{
                        background: #D3E1E9;
                        padding: 20px;
                        height: 50px;
                        border-radius: 0 0 15px 15px;

                        .title{
                            font-size: 24px;
                            font-family: 'Karla-Bold';
                            letter-spacing: 1px;
                            margin: 0;
                            color: $primary;
                            margin-bottom: 4px;
                        }

                        .navigation{
                            text-decoration: none;
                            color: black;
                            
                            @include lg{
                                font-size: 12px;
                            }

                            span{
                                margin-left: 8px;
                            }
                        }
                    }

                }
            }

            
            .nav-slider{
                display: flex;
                margin-top: 20px;

                @include lg{
                    margin-top: unset;
                    transform: translateY(10px);
                }

                @include md{
                    display: none;
                }

                @include lm{
                    display: none;
                }

                @include sm{
                    display: none;
                }


                button:nth-child(1){
                    height: 44px;
                    width: 44px;
                    /* left: 623px; */
                    /* top: 530px; */
                    border-radius: 30px;
                    display: flex;
                    border: none;
                    /* text-align: center; */
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                }

                button:last-child{
                    height: 44px;
                    width: 44px;
                    /* left: 623px; */
                    /* top: 530px; */
                    border-radius: 30px;
                    display: flex;
                    border: none;
                    background: rgba(231, 87, 70, 1);
                    /* text-align: center; */
                    justify-content: center;
                    align-items: center;
                }
            }

            .product::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
              }
        }
    }


    .dattabot{
        position: relative;
        display: flex;
        height: 1000px;

        @include md{
            height: 925px;
        }

        @include lg{
            height: 625px;
        }

        &:after{
            position: absolute;
            z-index: -1;
            content: '';
            color: white;
            height: 100%;
            width: 100%;
            background-color: $primary;
            transform-origin: bottom right;
            transform : skewY(4deg);
            box-shadow: 0 0 200px 0 $primary;

        }

        .wrapper-img {

            @include md{
                width: 60%;
            }
            
            img{
                width: 100%;

                @include md{
                    width: 85%;
                }
                
                @include lm{
                    width: 55%;
                }
            }
        }


        .content{
            flex-direction: column;
            align-content: flex-start;
            justify-content: center;
            box-sizing: border-box;
            display: flex;
            height: 60%;
            padding: 100px 120px 80px;
            position: relative;

            @include md{
                padding: 10px 40px 60px;
            }

            @include lm{
                padding: 20px 55px 60px;
            }

            @include lg{
                padding: 80px 60px;
            }

            h3{
                font-size: 48px;
                font-family: 'Karla-Bold';
                letter-spacing: 1.5px;
                color: #fafafa;
                margin: 0;

            }


            p{
                margin: 30px 0 50px;
                font-size: 20px;
                font-family: 'Karla' , sans-serif;
                letter-spacing: 0.5px;
                width: 85%;
                line-height: 30px;
                color: #fafafa;
            }

            a{
                width: 240px;
                height: 60px;
                color: #E75746;
                border-radius: 30px;
                font-family: 'Karla-Bold';
                font-size: 17px;
                letter-spacing: 0.5px;
                outline: none;
                border: none;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;

                &:active{
                    transform: scale(0.9);
                }

                @include md{
                    padding: 15px 0;
                }

                @include lm{
                    padding: 15px 0;
                }

                @include lg{
                    padding: 15px 0;
                }
            } 

            .traced{
                position: absolute;
                top: 100px;
                right: 0;

                @include lg{
                    height: 300px;
                }

                @include lm{
                    display: none;
                }

                @include sm{
                    display: none;
                }
            }
        }
    }


    .clients{
        height: 660px;

        @include sm{
            height: 600px;
        }

        .title{
            text-align: center;
        
            span:nth-child(1){
                letter-spacing: 1.5px;
                font-size: 48px;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $primary;
                color: white;
            }

            span:nth-child(2){
                margin-left: 20px;
                font-size: 48px;
                font-family: 'Karla-Bold';
                color: $primary;
                font-weight: 700;
                letter-spacing: 1.5px;
            }

        }

        .client-slider{
            display: flex;
            justify-content: center;
            padding: 20px 120px;

            @include md{
                padding: 20px 0px;
            }

            @include lm{
                padding: 20px 45px;
            }

            @include lg{
                padding: 20px;
            }


            .main{
                width: 100%;

                .slick-list{
                    padding: 60px 0;

                    .slick-track{
                        display: flex;
                        align-items: center;

                        .slick-slide img{
                            max-height: 220px;
                            width: 75%;

                            @include sm {
                                max-height: 250px;
                            }

                        }
                    }
                }

                .slick-dots li button::before {
                    font-size: 16px;
                    color: #DC4229;
                    opacity: 0.5;
                  }
                
                  .slick-dots li.slick-active button::before {
                    color: #DC4229;
                    opacity: 1;
                  }

            }
        }
    }

    .join{
        box-sizing: border-box;
        padding: 100px 0 0 100px;

        @include md{
            padding: 25px 0 0 40px;
        }

        .title{
            margin: 10px 0 0;
            font-size: 48px;
            font-family: 'Karla-Bold';
            letter-spacing: 1.5px;
            color: $primary;

            @include sm{
                font-size: 36px;
            }

        }

        .paragraph{
           
            font-size: 20px;
            letter-spacing: 0.5px;
            width: 80%;
            color: rgba(86, 111, 126, 1);
            line-height: 28px;
            font-family: 'Karla-Regular';
            margin: 30px 0 110px;

            @include md {
                width: 100%;
                margin: 30px 0 60px;
            }
    
        }

        .action{
            background-color: $primary;
            padding: 20px 30px;
            border-radius: 30px;
            font-size: 17px;
            font-family: 'Karla-Bold';
            border: none;
            color: white;
            letter-spacing: .5px;
            text-decoration: none;
                
        }

        .logo{
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;

            img{
                height: 500px;
            }
        }

        .back{
            position: absolute;
            bottom: -18px;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
            font-size: 14px;
            font-family: 'Karla-Bold';
            background-color: #e5e5e5;
            padding: 10px 25px;
            color: $primary;
            letter-spacing: 0.5px;
            border-radius: 30px;
            cursor: pointer;
        }
    }
}





@media (max-width : 468px){
    .home{
        padding-top: 40px;
        overflow-x: hidden;


        .landing{
            background-position: right;
            height: 100vh;
            overflow-x: hidden;

            .content{
                padding: 0 30px ;

                .content-title{
                    font-size: 24px;
                    line-height: 28px;
                }

                .content-body{
                    width: 100%;
                    font-size: 18px;
                }

                .content-action{
                    height: 50px;
                    width: 230px;
                    font-size: 16px;
                }
            }
        }

        .technology{
            padding: 40px 0;
    
            .content{
    
                .content-title{
                    padding: 0;
                    position: relative;

                    span:nth-child(1){
                        
                        font-size: 32px;
                        
                    }
            
                    span:nth-child(2){
                        margin-left: 10px;
                        font-size: 32px;
                     
                    }


                    &::after{
                        content: '';
                        width: 90px;
                        height: 3px;
                        background-color: $primary;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -15px;

                    }
                }
    
                .content-body{
                    color: #566f7e;
                    font-family: 'Karla-Regular';
                    letter-spacing: 0.5px;
                    padding: 20px 30px;
                    font-size: 18px;
                    
                }


                .group{

                    padding: 0 20px;


                    .wrapper{

                        .column-1{
                            flex-direction: row-reverse;

                            .content-1{

                                img{
                                    max-height: 230px;
                                    border-radius: 0 20px 0 0;
                                }
                            }

                            .content-4{
                                box-sizing: border-box;
                                padding: 6px 10px 0 20px;
                                height: 200px;

                                .body{
                                    line-height: 16px;
                                    font-size: 12px;
                                }

                                &::after{
                                    font-size: 75px;
                                    top: -15px;
                                    left: -5px;
                                }
                            }
                        }


                        .column-2{
                            flex-direction: row-reverse;
                            height: 200px;

                            .content-2{
                                box-sizing: border-box;
                                padding: 6px 0 0 25px;
                                position: relative;
                                height: 200px;

                            


                                .body{
                                    line-height: 16px;
                                    font-size: 12px;
                                }

                                &::after{
                                    font-size: 65px;
                                    left: -8px;
                                    top: -25px;

                                }
                            }


                            .content-5{

                                img{
                                    height: 200px;
                                }
                            }
                        }


                        .column-3{
                            flex-direction: row-reverse;

                            .content-6{
                                box-sizing: border-box;
                                padding: 6px 10px 0 30px;

                                &::after{
                                    font-size: 65px;
                                    top: -25px;
                                    left: -8px;
                                }

                                .body{
                                    line-height: 17px;
                                    font-size: 12px;

                                }
                            }


                            .content-3{

                                img{
                                    height: 150px;
                                    border-radius: 0 0 20px 0;
                                }
                            }
                        }
                    }
                }
            }
        }


        .solution{
            padding: 90px 30px 80px;

            .content{

                .information{

                    .title{
                        text-align: center;
                        position: relative;

                        span:nth-child(1) , span:nth-child(2){
                            display: inline;
                            font-size: 32px;
                            margin: 0 5px;
                        }

                        &::after{
                            content: '';
                            width: 90px;
                            height: 3px;
                            background-color: $primary;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -15px;
    
                        }

                    }

                    .body{
                        text-align: center;
                        font-size: 17px;
                        padding: 40px 0;
                    }
                }


                
                .product{
                    min-height: 300px;

                    .list{

                    .info{
                        padding: 15px;
                        height: 45px;

                        .title{
                            font-size: 17px;
                        }
                    }

                    .item{
                        max-height: 215px;
                        min-width:200px;
                        margin-right: 10px;

                        .item-img{
                            height: 100%;

                            img{
                                height: 100%;
                                width: 100%; 
                            }
                        }
                    }   
                }
            }
            }
        }

        .clients{

            .title{
                text-align: center;
                position: relative;
            
                span:nth-child(1){
                   
                    font-size: 38px;
                   
                }
    
                span:nth-child(2){
                    margin-left: 10px;
                    font-size: 38px;
               
                }

                &::after{
                        content: '';
                        width: 90px;
                        height: 3px;
                        background-color: $primary;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -15px;

                    }
            }

            .client-slider{
                padding: 0;
            }
    
        }

        .dattabot{
            height: 1000px;

            .row{

                @include md{
                    flex-direction: column;
                }


                .content{
                    padding: 0px 30px 10px 30px;
                    position: relative;
                    top: -60px;
    
                    @include md{
                        padding: 45px 35px 80px;
                    }
                    
                   
    
                    h3{
                        font-size: 38px;
                    }
    
                    p{
                        font-size: 18px;
                        width: 100%;
                    }
    
                    a{
                        height: 55px;
                        width: 225px;
                        padding: 15px 0;
                    }
                }
    
                .wrapper-img{
                    padding: 30px 0 0;

                    @include md{
                        width: 60%;
                    }
    
                    img{
                        width: calc(100% / 1.2 );
                    }
                }       
            }
        }

        .join{
            padding: 60px 0 0 30px;

            .wrapper{
                overflow-x: hidden;
                
            .title{
                font-size: 32px;

            }

            .logo img{
                height: 450px;
                display: block;
                margin: 0 auto;
                transform: translateX(10px);
                
            }
    
            .paragraph{
                margin: 30px 0 60px;
                width: 100%;
                font-size: 16px;
                
            }

            .action{
                padding: 15px 25px;
                font-size: 15px;
            }
            }
        }
    }  
}