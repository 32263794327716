$breakpoint : (
    "sm" : 468px ,
    "md" : 767px ,
    "lg" : 992px ,
    "xl" : 1280px 

) ;

@mixin sm {
    @media (max-width : map-get($breakpoint , "sm")){
        @content ;
    }
}

@mixin md {
    @media (max-width : map-get($breakpoint , "md")) and (min-width : map-get($breakpoint , "sm")){
        @content ;
    }
}

@mixin lm {
    @media (max-width : map-get($breakpoint , "lg")) and (min-width : map-get($breakpoint , "md")){
        @content ;
    }
}

@mixin lg {
    @media (max-width : map-get($breakpoint , "xl")) and (min-width : map-get($breakpoint , "lg")){
        @content ;
    }
}