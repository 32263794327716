@import '../../style/breakpoint.scss' ;

.notfound{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;

    @include md{
        padding: 0 40px;
    }

    .wrapper{
        justify-content: center;
        align-items: center;

        .logo-img {
            padding: 100px;

            @include md{
                padding: 40px;
            }
            
            img{
                width:  100%;
                
            }
        }

        .information{
            .title{
                font-size: 48px;
                font-family: 'Karla-Bold';
                letter-spacing: 1.5px;
                color: #DC4229;
                margin: 0 0 10px;
            }

            .body{
                font-size: 20px;
                letter-spacing: 0.5px;
                color: #434B49;
                font-family: 'Karla-Bold';
                margin: 10px 0;

            }


            .link-direction{
                text-decoration: none;
                background: #E75746;
                width: 230px;
                height: 52px;
                /* padding: 10px; */
                color: white;
                letter-spacing: 0.5px;
                margin: 20px 0;
                display: block;
                text-align: center;
                justify-content: center;
                display: flex;
                border-radius: 30px;
                align-items: center;
            }
        }
    }
}

@media (max-width : 468px){
    .notfound{

        .wrapper{

            .logo-img{
                padding: 40px 80px;

            }
        

        .information{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title{
                font-size: 28px;
                text-align: center;

            }

            .body{
                font-size: 17px;
            }

            .link-direction{
                width: 200px;
                height: 47px;
            }

        }
    }
}
}

