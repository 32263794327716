@import '../../style/breakpoint.scss' ;

.tech{
    padding-top: 0;

    @include sm{
        padding-top: 60px;
    }

    .jumbotron{
        height: 410px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-position: center;

        @include lg{
            height: 300px;
        }

        @include md{
            height: 225px;
        }

        @include lm{
            height: 225px;
        }

        @include sm{
            height: 150px;
        }

        .title{
            margin: 0;
            color: white;
            font-size: 48px;
            letter-spacing: 1.5px;
            font-family: 'Karla-Bold';

            @include sm{
                font-size: 32px;
            }

            @include md{
                font-size: 40px;
            }

            @include lm{
                font-size: 40px;
            }

            @include lg{
                font-size: 38px;
            }
        }
    }
}